import { Frame } from "@react95/core";
import { Chatshow3000, Mail3, Network2 } from "@react95/icons";
import React from "react";

function Contact() {
  const Mailto = ({ email, subject, body, children }) => {
    return (
      <a
        href={`mailto:${email}?subject=${
          encodeURIComponent(subject) || ""
        }&body=${encodeURIComponent(body) || ""}`}
      >
        {children}
      </a>
    );
  };

  return (
    <div>
      <Frame
        bg="white"
        boxShadow="in"
        height="100%"
        padding={20}
        style={{
          overflowY: "auto",
          maxHeight: "60vh",
        }}
      >
        <h2>Contact</h2>
        <div className="contact-container">
          <div className="contact">
            <Mailto email="coreywilliams733@gmail.com" subject="Hello">
              <Mail3
                style={{ width: "30px", height: "30px", marginRight: "10px" }}
              />
              <span>coreywilliams733@gmail.com</span>
            </Mailto>
          </div>
          <div className="contact">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://discordapp.com/users/339196150823256064"
            >
              <Chatshow3000 />
              Discord
            </a>
          </div>
          
        </div>
      </Frame>
    </div>
  );
}

export default Contact;
