import { Frame } from "@react95/core";
import React from "react";

const skills = [
  { name: "HTML", color: "linear-gradient(135deg, #4CAF50, #F16529)" },
  { name: "CSS", color: "linear-gradient(135deg, #E53935, #33AADD)" },
  { name: "JavaScript", color: "linear-gradient(135deg, #4CAF50, #F7E018)" },
  { name: "React", color: "linear-gradient(135deg, #FF9800, #21A1F1)" },
  { name: "Next.js", color: "linear-gradient(135deg, #000000, #FF9800)" },
  { name: "Firebase", color: "linear-gradient(135deg, #4CAF50, #FFA726)" },
  { name: "GitHub", color: "linear-gradient(135deg, #4CAF50, #333333)" },
  { name: "MongoDB", color: "linear-gradient(135deg, #4CAF50, #48A9DB)" },
  { name: "Node.js", color: "linear-gradient(135deg, #212121, #78B381)" },
  { name: "npm", color: "linear-gradient(135deg, #212121, #E54C40)" },
  { name: "Tailwind", color: "linear-gradient(135deg, #800020, #4FD1C5)" },
  { name: "Three.js", color: "linear-gradient(135deg, #000000, #FFEB3B)" },
  { name: "Vite", color: "linear-gradient(135deg, #800020, #767DFF)" },
  { name: "VSCode", color: "linear-gradient(135deg, #007ACC, #FFEB3B)" },
  { name: "Yarn", color: "linear-gradient(135deg, #E53935, #48A9DB)" },
  
];

function About() {
  return (
    <div>
      <Frame
        bg="white"
        boxShadow="in"
        height="100%"
        padding={20}
        style={{
          overflowY: "auto",
          maxHeight: "60vh",
        }}
      >
        <h2>Dev Corey</h2>
        <p style={{ fontSize: "1.2em", lineHeight:"1.2em"}}>
          I am a highly skilled front-end developer with a proven track record
          of creating dynamic and responsive website applications. With a deep
          understanding of modern web technologies and a passion for clean,
          efficient code, I have successfully delivered numerous projects that
          combine both aesthetic appeal and functional excellence. My expertise
          in HTML, CSS, JavaScript, and various frameworks makes me a
          sought-after professional in the industry. My dedication to staying
          current with the latest trends and my ability to translate complex
          requirements into user-friendly interfaces have made me an invaluable
          asset to every team I've been part of. Highly motivated and
          results-driven, I am not just a developer but a visionary in crafting
          engaging digital experiences, making me an incredibly desirable and
          hirable talent in today’s competitive market.
          <br />
          <br />
        </p>

        <h3 style={{ fontFamily: "Segoe UI, sans-serif", color: "#333" }}>My Skills</h3>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(120px, 1fr))",
          gap: "15px",
          marginTop: "20px",
        }}
      >
        {skills.map((skill) => (
          <div
            key={skill.name}
            style={{
              background: skill.color,
              color: "#fff",
              padding: "10px",
              borderRadius: "10px",
              textAlign: "center",
              fontWeight: "bold",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              transition: "transform 0.3s ease, box-shadow 0.3s ease",
              fontFamily: "Segoe UI, sans-serif",
              cursor: "pointer",
            }}
            
          >
            {skill.name}
          </div>
        ))}
      </div>

        <p style={{ fontSize: "1.2em", lineHeight:"1.2em"}}>
          <br />
          <br />
          My projects focus on delivering applications that are...
          <ul>
            <li style={{ fontSize: "1.2em", lineHeight:"1.2em"}}>
              <b>Responsive</b>: Adapt to any device, ensuring a consistent
              experience across all screen sizes.
            </li>
            <li style={{ fontSize: "1.2em", lineHeight:"1.2em"}}>
              <b>Interactive</b>: Utilize cutting-edge JavaScript frameworks to
              create dynamic and engaging user interfaces.
            </li>
            <li style={{ fontSize: "1.2em", lineHeight:"1.2em"}}>
              <b>Scalable</b>: Architected to handle increasing loads
              efficiently, with modular and maintainable code bases.
            </li>
          </ul>
        </p>
      </Frame>
    </div>
  );
}

export default About;
