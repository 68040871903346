import React, { useState } from "react";
import { FolderExe2 } from "@react95/icons";
import { Modal } from "@react95/core";
import DesktopIcon from "../DesktopIcon";

function Docs() {
  const [open, setOpen] = useState(false);
  const isMobile = window.innerWidth < 850;

  return (
    <div>
      <h2>Docs</h2>

      <div className="Documents">
        <DesktopIcon
          ico={<FolderExe2 />}
          className="docfolders"
          text="About Me"
          eventType={"about"}
        />
        <DesktopIcon
          ico={<FolderExe2 />}
          className="docfolders"
          text="Resume"
          eventType={"resume"}
        />
       
      </div>

      {open && (
        <Modal
          title={`CoreyWilliams.network`}
          closeModal={() => setOpen(false)}
          style={{
            left: isMobile ? "5%" : "10%",
            top: isMobile ? "3%" : "5%",
            width: isMobile ? "90%" : 450,
          }}
        ></Modal>
      )}
    </div>
  );
};

export default Docs;
