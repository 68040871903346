import React from "react";
import About from "./About";
import Project1 from "./Project1";
import Docs from "./Documents";
import Contact from "./Contact";
import Project3 from "./ChatSphere";
import Randomness from "./Randomness";
import NovaCart from "./NovaCart";
import DelayedTxs from "./DelayedTxs";

function ContentFactory({ id, isMobile }) {
  switch (id) {
    case "about":
      return <About />;
    case "contact":
      return <Contact />;
    case "project1":
      return <Project1 />;
    case "docs":
      return <Docs />;
    case "ChatSphere":
      return <Project3 />;
    case "randomness":
      return <Randomness />;
    case "NovaCart":
      return <NovaCart />;
    case "dtxs":
      return <DelayedTxs />;
    default:
      return <div></div>;
  };
};

export default ContentFactory;
