import { List, TaskBar } from "@react95/core";
import {
  Explore,
  FolderExe,
  FolderFile,
  Help,
  HelpBook,
  Mail2,
  Network3,
} from "@react95/icons";
import { EventEmitter } from "./EventEmitter";
// import { useContext } from "react";

export default function WebsiteTaskbar(props) {
  const handleClick = (x) => {
    switch (x) {
      case "resume":
        window.open("CoreyWilliamsResume2.pdf");
        break;
      case "project 1":
        window.open("https://shoppingcart.coreymwilliams.net/", "_blank");
        break;
      case "NovaCart":
        window.open("https://novacart-44g8.onrender.com/", "_blank");
        break;
      case "ChatSphere":
        window.open("https://chatapp-8vkh.onrender.com/", "_blank");
        break;
      default:
        EventEmitter.dispatch(x, true);
        break;
    }
  };

  return (
    <TaskBar
      list={
        <List>
          <List.Item icon={<FolderExe />}>
            <span onClick={() => handleClick("programs")}>Programs</span>
            <List>
              <List.Item
                icon={<Explore />}
                onClick={() => handleClick("project 1")}
              >
                <span>Project 1</span>
              </List.Item>
              <List.Item
                icon={<Explore />}
                onClick={() => handleClick("NovaCart")}
              >
                NovaCart
              </List.Item>
              <List.Item
                icon={<Explore />}
                onClick={() => handleClick("ChatSphere")}
              >
                ChatSphere
              </List.Item>
            </List>
          </List.Item>
          <List.Item icon={<FolderFile />} onClick={() => handleClick("")}>
            <span>Documents</span>
            <List>
              <List.Item
                icon={<FolderFile />}
                onClick={() => handleClick("about")}
              >
                About Me
              </List.Item>
              <List.Item
                icon={<FolderFile />}
                onClick={() => handleClick("resume")}
              >
                Resume
              </List.Item>
             
            </List>
          </List.Item>
          <List.Item icon={<HelpBook />}>
            Find
            <List>
              <List.Item onClick={() => handleClick("randomness")}>
                Randomness
              </List.Item>
              <List.Item onClick={() => handleClick("dtxs")}>
                Delayed Transactions
              </List.Item>
            </List>
          </List.Item>
          <List.Divider />
          <List.Item icon={<Help />} onClick={() => handleClick("about")}>
            <span>About</span>
          </List.Item>
          <List.Item icon={<Mail2 />} onClick={() => handleClick("contact")}>
            <span>Contact</span>
          </List.Item>
        </List>
      }
    />
  );
}
