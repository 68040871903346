import React from "react";

function Programs() {
  return (
    <div>
      <h4
        style={{
          fontSize: "1.4em",
        }}
      >
        Spin Down Host, Please Allow Time for Loading 
      </h4>
      <ul>
        <li
          style={{
            fontSize: "1.2em",
            lineHeight: "1.4em",
          }}
        >
          <a
            target="_blank"
            rel="noreferrer"
            href="https://chatapp-8vkh.onrender.com/"
            style={{
              fontWeight: "bold",
              color: "#007bff",
              textDecoration: "underline",
              fontSize: "1.34em",
            }}
          >
            ChatSphere
          </a>{" "}
          Introducing ChatSphere, the ultimate real-time messaging app designed
          for seamless, instant communication with friends. Powered by the
          robust combination of MongoDB, Socket.io, React, and Next.js, this
          cutting-edge platform delivers lightning-fast message exchanges and an
          ultra-responsive user experience. Whether you're sharing quick
          updates or having deep conversations, ChatSphere ensures your
          chats are secure, scalable, and always in sync. Built with modern
          technology for effortless communication, it's the perfect solution for
          staying connected with those who matter most.
        </li>
      </ul>
    </div>
  );
}

export default Programs;
