import React, { useState, useEffect } from "react";
import Notepad from "./Notepad";
import "../App.css";
import { EventEmitter } from "./EventEmitter";
import { Modal } from "@react95/core";
import {
  Explorer100,
  FlyingThroughSpace100,
  FolderExe2,
  FolderFile,
  InfoBubble,
  Mail3,
  Network2,
  QuestionBubble,
  Time,
  Timedate200,
  Url105,
} from "@react95/icons";
import DesktopIcon from "./DesktopIcon";
import Docs from "./NotepadContent/Documents";

function WebsiteDesktop(props) {
  const isMobile = window.innerWidth < 850;
  const [openEditors, setOpenEditors] = useState([]);
  const [closeInfo, setCloseInfo] = useState(false);
  const [isLabModalOpen, setIsLabModalOpen] = useState(true); // Add state to manage modal visibility

  const editors = [
    { id: "about", name: "About", ico: <QuestionBubble /> },
    { id: "project1", name: "Project 1", ico: <FolderExe2 /> },
    { id: "NovaCart", name: "NovaCart", ico: <Time /> },
    { id: "ChatSphere", name: "ChatSphere", ico: <Network2 /> },
    { id: "docs", name: "Documents", ico: <FolderFile /> },
    { id: "contact", name: "Contact", ico: <Mail3 /> },
    { id: "randomness", name: "Randomness", ico: <Url105 /> },
    { id: "dtxs", name: "Delayed Transactions", ico: <Timedate200 /> },
  ];
  const Mailto = ({ email, subject, body, children }) => {
    return (
      <a
        href={`mailto:${email}?subject=${
          encodeURIComponent(subject) || ""
        }&body=${encodeURIComponent(body) || ""}`}
      >
        {children}
      </a>
    );
  };

  useEffect(() => {
    /* setOpenEditors([{id: 'about', name: 'About', ico: <QuestionBubble />}]); */

    editors.forEach((editor) => {
      EventEmitter.subscribe(editor.id, () => {
        openNotepad(editor);
      });
    });
    // eslint-disable-next-line
  }, []);

  const getNextX = (n) => {
    return `${35 + 5 * n}%`;
  };

  const getNextY = (n) => {
    return `${15 + 5 * n}%`;
  };

  const closeNotepad = (id) => {
    setOpenEditors(openEditors.filter((editor) => editor.id !== id));
  };

  const openNotepad = (item) => {
    setOpenEditors((prevEditors) => {
      if (!prevEditors.some((editor) => editor.id === item.id)) {
        return [...prevEditors, item];
      }
      return prevEditors;
    });
  };

  return (
    <>
      {isLabModalOpen && (
        <Modal
          icon={<FlyingThroughSpace100 />}
          title={`CoreyWilliams.network`}
          closeModal={() => setIsLabModalOpen(false)}
          style={{
            left: isMobile ? "5%" : "10%",
            top: isMobile ? "3%" : "5%",
            width: isMobile ? "90%" : 450,
          }}
        >
          <div className="centered">
            <span className="title">Corey Williams FrontEnd Developer</span>
            <div className="links">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://github.com/Imbalancement"
              >
                Github
              </a>
              |
              <Mailto email="coreywilliams733@gmail.com" subject="Hello">
                Email
              </Mailto>
              |
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/in/corey-williams-88987727b/"
              >
                LinkedIn
              </a>
            </div>
          </div>
        </Modal>
      )}
      {closeInfo && (
        <Modal
          icon={<InfoBubble />}
          title={`info`}
          closeModal={() => setCloseInfo(false)}
          style={{
            left: isMobile ? "50%" : "10%",
            top: isMobile ? "30%" : "25%",
            width: isMobile ? "90%" : 450,
          }}
        >
          <div className="centered">
            <span>This website is best viewed on a laptop or PC.</span>
          </div>
        </Modal>
      )}
      {openEditors.map((editor, idx) => (
        <Notepad
          key={editor.id}
          closeNotepad={() => closeNotepad(editor.id)}
          ico={editor.ico}
          selectedItem={editor}
          isMobile={isMobile}
          left={getNextX(idx)}
          top={getNextY(idx)}
        />
      ))}
      <div className="init-icons">
        <DesktopIcon
          ico={<FolderFile variant="32x32_4" />}
          className="ico"
          text="Documents"
          eventType={"docs"}
        />
        <DesktopIcon
          ico={<FolderExe2 />}
          className="ico"
          text="Project One"
          eventType={"project1"}
        />
        <DesktopIcon
          ico={<FolderExe2 />}
          className="ico"
          text="NovaCart"
          eventType={"NovaCart"}
        />
        <DesktopIcon
          ico={<FolderExe2 />}
          className="ico"
          text="ChatSphere"
          eventType={"ChatSphere"}
        />
        <DesktopIcon
          ico={<Explorer100 />}
          className="ico"
          text="Resume"
          eventType={"resume"}
        />
      </div>
    </>
  );
}

export default WebsiteDesktop;
