import React from "react";

function Programs() {
  return (
    <div>
      <h4
        style={{
          fontSize: "1.4em",
        }}
      >
       Spin Down Host, Please Allow Time for Loading
      </h4>
      <ul>
        <li
          style={{
            fontSize: "1.2em",
            lineHeight: "1.2em",
          }}
        >
          <a
            target="_blank"
            rel="noreferrer"
            href="https://novacart-44g8.onrender.com/"
            style={{
              fontWeight: "bold",
              color: "#007bff",
              textDecoration: "underline",
              fontSize: "1.34em",
            }}
          >
            NovaCart
          </a>{" "}
          is a full-featured e-commerce platform designed to deliver a seamless
          shopping experience with robust backend functionality. It includes a
          secure payment checkout system that supports multiple payment methods,
          ensuring safe and reliable transactions for users. Novacart also
          provides real-time analytics, with detailed graphs that allow
          administrators to track sales trends, customer behavior, and
          performance metrics. The admin dashboard offers complete control over
          the website’s content, allowing for easy updates to product listings,
          pricing, and promotions. Designed with scalability and user experience
          in mind, Novacart combines modern frontend aesthetics with a powerful
          backend architecture to optimize online retail operations.
        </li>
      </ul>
    </div>
  );
}

export default Programs;
