import React from "react";

function Programs() {
  return (
    <div>
      <h4 style={{
        fontSize:"1.4em"
      }}>Projects</h4>
      <ul>
        <li 
        style={{
          fontSize:"1.2em",
          lineHeight:"1.2em"
        }}>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://shoppingcart.coreymwilliams.net/"
            style={{
              fontWeight: "bold",
              color: "#007bff",
              textDecoration: "underline",
              fontSize: "1.4em",
            }}
          >
            Shopping Cart
          </a>{" "}
          is website designed to streamline the online shopping experience. The
          site allows users to effortlessly browse through a wide range of
          products, using a robust search function that quickly narrows down
          results based on keywords, categories, and price filters. Once a
          product is selected, adding it to the cart is as simple as a single
          click. The cart itself is dynamic, allowing users to view, update
          quantities, or remove items with ease, ensuring they have complete
          control over their purchase before proceeding. The checkout process is
          intuitive and secure, guiding users through payment and shipping
          options, and confirming their order with a sleek, user-friendly
          interface. This project showcases my ability to create a seamless,
          engaging, and highly functional e-commerce platform.
        </li>
      </ul>
    </div>
  );
}

export default Programs;
